<template>
  <div class="mt-4">
    <v-card flat color="secondary">
      <v-card-text>
        <!-- <div class="text-right"> -->
        <v-btn
          color="accent"
          outlined
          v-if="rooms.length > 0"
          class="mb-2"
          @click="showAssignDialog = true"
        >
          Asignar
        </v-btn>
        <!-- </div> -->
        <emb-perfect-scrollbar class="scroll-area" style="height: 70vh;">
          <v-row dense>
            <v-col
              cols="12"
              xl="1"
              lg="1"
              md="2"
              sm="3"
              class="mb-0"
              v-for="(room, i) in records"
              :key="i"
            > 
              <v-card color="info" hover dark>
                <el-tooltip
                  class="item"
                  effect="dark"
                  content="Asignar"
                  placement="top"
                >
                  <el-checkbox
                    v-model="room.checked"
                    class="float-right ma-2"
                    @change="checkAddRoom(room.checked, room.id)"
                    v-if="room.room_state_id == '03'"
                  ></el-checkbox>
                </el-tooltip>
                <div>
                  <v-row dense>
                    <v-col cols="12" lg="12" class="text-center">
                      <h4 class="white--text py-1">{{ room.name }}</h4>
                      <h3 class="white--text">{{ room.room_type  }}</h3>
                    </v-col>
                  </v-row>
                </div>
                <v-card-actions class="pt-0">
                  <v-spacer></v-spacer>
                  <template v-if="room.room_state_id == '05'">
                    <v-btn
                      class="ml-1"
                      outlined
                      rounded
                      small
                      @click.prevent="finishCleaning(room.id)"
                    >
                      <v-icon>mdi-broom</v-icon>
                    </v-btn>
                  </template>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </emb-perfect-scrollbar>
      </v-card-text>
    </v-card>

    <assign-form :showAssignDialog.sync="showAssignDialog" :rooms="rooms">
    </assign-form>
  </div>
</template>

<script>
import { deletable } from "Mixins/deletable";
import { confirm } from "Mixins/confirm";
import queryString from "query-string";
import AssignForm from "./Assign.vue";

export default {
  mixins: [deletable, confirm],
  components: { AssignForm },
  data() {
    return {
      resource: "cleaning",
      isIndeterminate: true,
      showAssignDialog: false,
      loaded: false,
      records: [],
      rooms: [],
      tab: null,
      recordID: null,
      salonID: null,
      search: {
        room_type_id: null,
        room_state_id: null,
      },
    };
  },
  async created() {
    await this.getRecords();
    
    await this.$eventHub.$on("reloadDataCleaning", (rsc) => {
      if (this.resource == rsc) {
        this.getRecords();
      }
    });
  },
  methods: {
    getRecords() {
      this.$http.get(`/${this.resource}/records`).then((response) => {
        this.records = response.data.data;
      });
    },
    finishCleaning(roomID) {
      this.cleaning(`/${this.resource}/finish/room/cleaning/${roomID}`).then(
        () => {
          this.$eventHub.$emit("reloadDataCleaning", this.resource);
          this.rooms = []
        }
      );
    },
    checkAddRoom(checked, room_id) {
      let exist = this.rooms.find((item) => item.room_id == room_id);
      let room = this.records.find((item) => item.id == room_id);
      if (checked) {
        if (!exist) {
          this.rooms.push({
            room_id: room_id,
            name: room.name,
          });
        }
      } else if (!checked && exist) {
        this.rooms.forEach((row, index) => {
          if (row.room_id === exist.room_id) {
            this.rooms.splice(index, 1);
          }
        });
      }
    },
    getQueryParameters() {
      return queryString.stringify({
        ...this.search,
      });
    },
  },
};
</script>
