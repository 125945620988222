<template>
  <div class="mt-4">
    <div class="d-flex justify-space-between ">
      <div class="input-wrap">
        <h6 class="mb-2">Recepción</h6>
      </div>
      <div class="action-btn-wrap">
        <v-row dense>
          <v-col cols="12" xl="4" lg="4" md="4" sm="4" class="text-right">
            <v-btn color="accent" :to="{ name: 'RentAdd'}" outlined>
              Punto de venta
            </v-btn>
          </v-col>
          <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="6">
            <el-select
              size="medium"
              clearable
              v-model="search.room_state_id"
              placeholder="Estado"
              @change="getRoomsBySalon(currentSalonID)"
            >
              <el-option
                v-for="option in roomStates"
                :key="option.id"
                :value="option.id"
                :label="option.name"
              ></el-option>
            </el-select>
          </v-col>
          <v-col cols="12" xl="4" lg="4" md="4" sm="4" xs="6">
            <el-select
              size="medium"
              clearable
              v-model="search.room_type_id"
              placeholder="Tipo de habitación"
              @change="getRoomsBySalon(currentSalonID)"
            >
              <el-option
                v-for="option in roomTypes"
                :key="option.id"
                :value="option.id"
                :label="option.name"
              ></el-option>
            </el-select>
          </v-col>
          
        </v-row>
      </div>
    </div>

    <v-card flat color="secondary" v-if="opened_cash && loaded">
      <v-tabs
        v-model="tab"
        centered
        background-color="primary"
        fixed-tabs
        dark
        small
      >
        <v-tab href="#tab-1" @click.prevent="getRoomsBySalon(currentSalonID)">
          TABLERO
        </v-tab>
        <v-tab href="#tab-2" @click.prevent="loadRoomsInUse()">
          HAB. OCUPADAS
        </v-tab>
        <v-tab href="#tab-3" @click.prevent="loadCleaning()">
          HAB. EN LIMPIEZA
        </v-tab>

        <v-tabs-items v-model="tab">
          <v-tab-item :key="1" :value="'tab-' + 1" >
            <v-card-text> 
              <emb-perfect-scrollbar class="scroll-area" style="height: 70vh;">
                <v-row dense class="mt-3">
                  <v-col
                    cols="12"
                    xl="1"
                    lg="1"
                    md="2"
                    sm="3"
                    class="mb-0"
                    v-for="(room, i) in rooms"
                    :key="i"
                  >
                    <v-card :color="room.color" hover dark @click="room.room_state_id == '01' ?  clickAddRental(room.id) : ''">
                      <div>
                        <v-row dense>
                          <v-col cols="12" lg="12" class="text-center">
                            <h4 class="white--text py-1">{{ room.name }}</h4>
                            <h3 class="white--text">{{ room.room_type_short  }}</h3>
                          </v-col>
                        </v-row>
                      </div>
                      <v-card-actions class="pt-0">
                      <v-spacer></v-spacer>

                        <!-- <template v-if="room.rental_item_id">
                          <v-btn
                            v-if="room.diffInMins < 10"
                            class="ml-1"
                            outlined
                            rounded
                            small
                            @click.prevent="
                              clickCancelRental(room.rental_item_id, room.salon_id)
                            "
                          >
                            <v-icon small>mdi-close</v-icon>
                          </v-btn>
                          <v-btn
                            class="ml-1"
                            outlined
                            rounded
                            small
                            @click.prevent="
                              clickCheckout(room.rental_item_id, room.salon_id)
                            "
                          >
                            <v-icon>mdi-arrow-right-bold</v-icon>
                          </v-btn>
                        </template> -->
                        <!-- <template v-if="room.room_state_id == '03'">
                          <v-btn
                            class="ml-1"
                            outlined
                            rounded
                            small
                            @click.prevent="finishCleaning(room.id, room.salon_id)"
                          >
                            <v-icon>mdi-broom</v-icon>
                          </v-btn>
                        </template> -->
                      </v-card-actions>
                    </v-card>
                  </v-col>
                </v-row>
              </emb-perfect-scrollbar>
              <v-row dense>
                <v-col cols="6" lg="6" md="6" sm="12">
                  <v-chip
                    x-small
                    class="mt-2 mr-1"
                    color="success"
                    label
                    text-color="white"
                  >
                    Disponible
                  </v-chip>
                  <v-chip
                    x-small
                    class="mt-2 mx-1"
                    color="error lighten-1"
                    label
                    text-color="white"
                  >
                    Ocupado
                  </v-chip>
                  <v-chip
                    x-small
                    class="mt-2 mx-1"
                    color="info"
                    label
                    text-color="white"
                  >
                    Limpieza
                  </v-chip>
                  <v-chip
                    x-small
                    class="mt-2 mx-1"
                    color="warning"
                    label
                    text-color="white"
                  >
                    Mantenimiento
                  </v-chip>
                </v-col>

                <v-spacer></v-spacer>
                <!-- <v-col cols="6" lg="6" md="6" sm="12" class="text-right">
                  <v-chip
                    x-small
                    class="mt-2 mx-1"
                    color="accent"
                    label
                    text-color="white"
                    v-for="(salon, index) in salons"
                    @click.prevent="getRoomsBySalon(salon.id)"
                    :key="index"
                  >
                    {{ salon.name }}
                  </v-chip>
                </v-col> -->
              </v-row>
            </v-card-text>
          </v-tab-item> 
          <v-tab-item :key="2" :value="'tab-' + 2">
            <emb-rooms-in-use></emb-rooms-in-use>
          </v-tab-item>
          <v-tab-item :key="3" :value="'tab-' + 3">
            <emb-cleaning></emb-cleaning>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
      
    </v-card>
    <v-row v-if="!opened_cash && loaded">
      <v-col cols="12" xl="12">
        <el-alert
          title="Usted no podrá realizar alquileres porque no ha aperturado caja."
          type="error"
          center
          effect="dark"
          :closable="false"
        ></el-alert>
      </v-col>
    </v-row>
    <checkout-form
      :showCheckoutDialog.sync="showCheckoutDialog"
      :recordID="recordID"
      :salonID="salonID"
    >
    </checkout-form>
    <cancel-form
      :showCancelDialog.sync="showCancelDialog"
      :recordID="recordID"
      :salonID="salonID"
    >
    </cancel-form>
  </div>
</template>

<script>
import CheckoutForm from "./Component/Checkout";
import CancelForm from "./Component/Cancellation";
import { deletable } from "Mixins/deletable";
import { confirm } from "Mixins/confirm";
import queryString from "query-string";

import RoomsInUse from 'Views/Admin/RoomsInUse/Index';
import Cleaning from 'Views/Admin/Cleaning/Index';

export default {
  components: { 
    CheckoutForm,
    CancelForm,
    embRoomsInUse: RoomsInUse,
    embCleaning: Cleaning,
  },
  mixins: [deletable, confirm],
  data() {
    return {
      resource: "rentals",
      opened_cash: false,
      showCheckoutDialog: false,
      showCancelDialog: false,
      loaded: false,
      salons: [],
      rooms: [],
      tab: null,
      recordID: null,
      salonID: null,
      roomStates: [
        { id: "01", name: "Disponible" },
        { id: "02", name: "Ocupado" },
        { id: "03", name: "En Limpieza" },
      ],
      roomTypes: [],
      search: {
        room_type_id: null,
        room_state_id: null,
      },
      currentSalonID: null,
      intervalID: "",
    };
  },
  async created() {
    await this.getCashOpened();
    await this.getTables();

    await this.$eventHub.$on("reloadDataRentals", (salonID) => {
      this.getRoomsBySalon(salonID);
    });
  },
  mounted: function() {
    this.reloadRooms();
  },
  beforeDestroy() {
    clearInterval(this.intervalID);
  },
  methods: {
    reloadRooms: function() {
      this.intervalID = setInterval(
        function() {
          this.getRoomsBySalon(this.currentSalonID);
        }.bind(this),
        120000
      );
    },
    getCashOpened() {
      this.$http.get(`/constants/opened-cash`).then((response) => {
        this.opened_cash = response.data;

        this.loaded = true;
      });
    },
    loadRoomsInUse() {
      this.$eventHub.$emit("reloadDataRoomsInUse", 'rooms-in-use');
    },
    loadCleaning() {
      this.$eventHub.$emit("reloadDataCleaning", 'cleaning');
    },
    getTables() {
      // if (localStorage.getItem("salons")) {
      //   this.salons = JSON.parse(localStorage.getItem("salons"));
      //   this.getRoomsBySalon(this.salons[0].id);
      // } else {
      this.$http.get(`/${this.resource}/salons`).then((response) => {
        this.salons = response.data.salons;
        this.roomTypes = response.data.room_types;
        if (this.salons.length > 0) {
          this.getRoomsBySalon(this.salons[0].id);
          this.currentSalonID = this.salons[0].id;
          // localStorage.setItem("salons", JSON.stringify(this.salons));
        }
      });
      // }
    },
    getRoomsBySalon(salonId) {
      this.currentSalonID = salonId;
      this.$http
        .get(
          `/${
            this.resource
          }/tables/by-salon/${salonId}?${this.getQueryParameters()}`
        )
        .then((response) => {
          this.rooms = response.data;
        });
    },
    clickAddRental(tableId) {
      this.$router.push({ name: "RentAddSelected", params: { id: tableId } });
    },
    // clickCancelRental(id, salon_id) {
    //   this.close(`/${this.resource}/cancel-definitive/${id}`).then(() => {
    //     this.getRoomsBySalon(salon_id);
    //   });
    // },
    clickCheckout(rentalItemID, salonID) {
      this.recordID = rentalItemID;
      this.salonID = salonID;
      this.showCheckoutDialog = true;
      // this.checkout(`/${this.resource}/rent/checkout/${rentalID}`).then(() => {
      //   this.getRoomsBySalon(salonID);
      // });
    },
    clickCancelRental(rentalItemID, salonID) {
      this.recordID = rentalItemID;
      this.salonID = salonID;
      this.showCancelDialog = true;
    },
    finishCleaning(roomID, salonID) {
      this.cleaning(`/${this.resource}/finish/room/cleaning/${roomID}`).then(
        () => {
          this.getRoomsBySalon(salonID);
        }
      );
    },
    getQueryParameters() {
      return queryString.stringify({
        ...this.search,
      });
    },
  },
};
</script>
