<template>
  <el-dialog
    :visible="showAssignDialog"
    @close="close"
    @open="create"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :show-close="false"
    width="35%"
    title="Limpieza"
  >
    <form autocomplete="off" @submit.prevent="submit">
      <v-row>
        <v-col cols="12" xl="12" lg="12" md="12" sm="12">
          <div
            class="form-group"
            :class="{ 'error--text': errors.cleaning_personnel_id }"
          >
            <label>Encargado de limpieza</label>
            <el-select
              v-model="form.cleaning_personnel_id"
              dusk="cleaning_personnel_id"
            >
              <el-option
                v-for="option in cleaning_personnel"
                :key="option.id"
                :value="option.id"
                :label="option.name"
              ></el-option>
            </el-select>
            <small
              class="error--text"
              v-if="errors.cleaning_personnel_id"
              v-text="errors.cleaning_personnel_id[0]"
            ></small>
          </div>
        </v-col>
        <v-col cols="12" xl="12" lg="12" md="12" sm="12">
          <div
            v-for="(room, index) in details"
            :key="index"
          >
              <div class="text-center">
                <strong>{{ room.name }}</strong>
              </div>
              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left" width="10%">
                        
                      </th>
                      <th class="text-left" width="50%">
                        Nombre
                      </th>
                      <th class="text-left" width="40%">
                        Cantidad
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, i) in room.its"
                      :key="i"
                    >
                      <td>
                        <el-checkbox
                          v-model="item.delivered"
                        ></el-checkbox>
                      </td>
                      <td>{{ item.name }}</td>
                      <td>
                        <el-input-number
                        :min="1"
                        v-model="item.quantity"
                        >
                        </el-input-number>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" xl="12" lg="12" md="12" sm="12" class="text-right">
          <el-button type="info" @click.prevent="close()">Cancelar</el-button>
          <el-button
            type="primary"
            native-type="submit"
            :loading="loading"
            :disabled="loading || !this.form.cleaning_personnel_id"
          >
            <template v-if="loading">
              Guardando...
            </template>
            <template v-else>
              Guardar
            </template>
          </el-button>
        </v-col>
      </v-row>
    </form>
  </el-dialog>
</template>

<script>
export default {
  props: ["showAssignDialog", "rooms"],
  data() {
    return {
      loading: false,
      titleDialog: null,
      resource: "cleaning",
      cleaning_personnel: [],
      items: [],
      details: [],
      errors: {},
      form: {},
    };
  },
  methods: {
    initForm() {
      this.errors = {};
      this.form = {
        cleaning_personnel_id: null,
        rooms: this.details
      };
    },
    async create() {
      await this.initForm();
      await this.getTables();
    },
    async getTables() {
      await this.$http
        .get(`/${this.resource}/tables`)
        .then((response) => {
          this.cleaning_personnel = response.data.personnels;
          this.items = response.data.items;

          this.initRooms();
        });
    },
    initRooms() {
      this.details = [];
      this.rooms.forEach(room => {
        // const it = this.items;
        this.details.push({
          id: room.room_id,
          name: room.name,
          its: JSON.parse(JSON.stringify(this.items)),
        })
      });
    },
    submit() {
      this.loading = true;
      this.$http
        .post(`/${this.resource}`, {
          cleaning_personnel_id: this.form.cleaning_personnel_id,
          rooms: this.details
        })
        .then((response) => {
          if (response.data.success) {
            this.$message.success(response.data.message);
            this.$eventHub.$emit("reloadDataCleaning", this.resource);
            this.close();
          } else {
            this.$message.error(response.data.message);
          }
        })
        .catch((error) => {
          if (error.response.status === 422) {
            this.errors = error.response.data.message;
          } else {
            console.log(error);
          }
        })
        .then(() => {
          this.loading = false;
        });
    },
    close() {
      this.$emit("update:showAssignDialog", false);
      this.initForm();
    },
  },
};
</script>
